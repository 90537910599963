const locale = {
	APP_TITLE: 'بريد',
	COMPOSE: 'بريد جديد',
	FOLDERS: 'المجلدات',
	FILTERS: 'مرشحات',
	LABELS: 'ملصقات',
	NO_MESSAGES: 'لا توجد رسائل!',
	SEARCH_PLACEHOLDER: 'ابحث عن بريد إلكتروني أو جهة اتصال',
	INBOX: 'صندوق الوارد',
	SENT: 'أرسلت',
	DRAFTS: 'لعبة الداما',
	SPAM: 'بريد مؤذي',
	TRASH: 'قمامة، يدمر، يهدم',
	STARRED: 'بطولته',
	IMPORTANT: 'مهم'
};

export default locale;
