// import React from 'react';

const CompactInvoicePageConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: []
};

export default CompactInvoicePageConfig;
